.list {
    /* position: relative; */
    width: 100%;
    /* margin: 34px; */
    /* font-size: calc(34px * 0.9); */
}

.list,
.list:after,
.list:before,
.list *,
.list *:after,
.list *:before {
    box-sizing: border-box;
}

.list>div {
    position: absolute;
    will-change: transform, width, height, opacity;
    padding: 0px 0px 55px 21px;
    
    display: flex;
    width: 233px;
    min-width: 144px;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
}

.preview {
    display: flex;
    flex-direction: column;
    padding: 0px 17px 0px;
    gap: 8px;
    cursor: pointer;
}

.card {
    position: relative;
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 17px;
    /* box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2); */
    z-index: 100;
}

.card-img {
    position: absolute;
    height: 100%;
    width: 100%;
}

.card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-img>svg {
    display: block;
    width: 100%;
    height: 100%;
    transform: unset !important;
    /* so lottie animations don't appear blurry on Chrome */
}

.card-content {
    position: absolute;
    /* width: 100%; */
    /* display: flex;
    height: 100%;
    padding: 26px 34px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch; */
    top: 26px;
    left: 34px;
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: flex-end; */
    /* padding: 0.618em;
    gap: 0.618em */
    z-index: 0;
}

.card-content.inverted {
    color: white;
}

/* .card-content>.logo {
    display: block;
    line-height: 0;
}

.card-content>.logo>svg {
    height: 1em;
}

.card-content>.logo>svg#the-shirt,
svg#vinal {
    height: 1.618em;
} */

/* .details-container>div:not(.line-container) {
    margin-bottom: calc(89rem / 34);
} */

/* .details-container>.notice {
    width: 61.8%;
    margin: 0 auto;
    text-align: center;
    margin-top: 1.618em;
} */

.cover-container {
    position: relative;
    width: 100%;
    height: 322px;
    border-radius: calc(17px);
    overflow: hidden;
    margin-bottom: 21px;
}

.cover-content {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 99;
    padding: 1.618em 1em 1em;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1em;
    color: white;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.38) 85%, rgba(0, 0, 0, 0.38) 100%);
}

/* .info {
    margin-bottom: calc(21rem / 34);
} */

.key-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 21px;
}

.cover-container>img {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    object-fit: cover;
}

.cover-container>img>* {
    height: 100%;
    width: 100%;
}

.cover-container>img>svg {
    display: block;
}

.cover-container>img>img {
    object-fit: cover;
}

.details-container {
    display: flex;
    padding: 0px 55px;
    flex-direction: column;
    align-items: center;
    gap: 21px;
    align-self: stretch;
}

.header {
    align-self: flex-start;
}

.details-content {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 55px;
    align-self: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
}

.body {
    display: flex;
    min-width: 377px;
    max-width: 610px;
    flex-direction: column;
    align-items: flex-start;
    gap: 55px;
    flex: 1 0 0;
    align-self: center;
    align-self: baseline;
}

section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
    align-self: stretch;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 21px;
    align-self: flex-start !important;
    width: 233px;
}

.notice {
    width: 38vw;
    justify-self: center;
    text-align: center;
    padding-top: 89px;
}

/* section>div {
    display: flex;
    flex-direction: column;
    row-gap: 21px;
    flex: 1 0 50%;
}

section>div:first-child {
    flex: 0 1 38%;
} */

/* section section.img {
    padding: 0;
} */

.img {
    width: 100%;
}

.img>img,
.img>svg {
    width: 100%;
    /* min-width: 100%; */
    /* max-width: 100%; */
    border-radius: 17px;
    display: block;
}

/* .img>svg>* {
    width:100%;
    height: 100%;
} */

@media screen and (max-width: 418px) {
    .card-content {
        left: 21px;
    }
}

/* @media screen and (max-width: 855px) {
    .cover-container {
        height: calc(521rem / 34);
    }
}

@media screen and (max-width: 800px) {
    .section {
        flex-direction: column;
        padding: 0 0.618em;
    }

    .details-container>.notice {
        width: 100%;
    }

    .cover-content {
        padding: 1.618em 0.618em 0.618em;
        gap: 0.618em;
    }
}

@media screen and (max-width: 624px) {
    .details-container {
        padding: 61.19px 1.618rem calc(144rem / 34);
    }

    .cover-content h3 {
        font-size: .618em;
    }

    .cover-content h2 {
        font-size: .85em;
    }
}

@media screen and (max-width: 480px) {
    .list>div {
        padding: calc(0.5em * 0.618);
    }

    .details-container {
        padding: 61.19px calc(13rem / 34) calc(144rem / 34);
    }
}

@media screen and (max-height: 844px) {
    .cover-container {
        height: calc(466rem / 34);
    }
} */