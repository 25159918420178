:root {
  --primary-font: "azo-sans-web", sans-serif;
  --header-font: "grenadine-mvb", sans-serif;
  --sun-conure: #E2911B;
  --khaki: #EAD7A1;
  --scarlet: #DD4623;
  --forest: #060707;
  --spruce: #101111;
  --green-parakeet: #1a6653;
  --cool-neutral: #F5F0D3;

  --text-primary: #282828;
  --text-secondary: #535353;
  --neutral-tan-400: #F3F1ED;
}

html {
  background: var(--neutral-tan-400, #F3F1ED);
}

html,
body {
  /* Regular */
  font-family: "Mozaic HUM Variable", "mozaic-hum-variable", sans-serif;
  color: var(--text-primary, #282828);
  font-size: 16px;
  font-style: normal;
  font-weight: 1 388;
  /* font-variation-settings: 'wght' 388; */
  line-height: 26px;
  /* 162.5% */
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  /* -webkit-tap-highlight-color: rgba(0,0,0,0.05); */
}

html * {
  margin: 0;
}

#root {
  display: flex;
  width: 100vw;
  justify-content: center;
  /* flex-direction: column; */
  align-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  padding: 0;
  list-style: none;
}