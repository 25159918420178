.home {
    display: flex;
    max-width: 699px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    /* padding: 0px 34px;
    box-sizing: border-box; */
    align-self: stretch;
}

.home>* {
    display: flex;
    padding: 0px 34px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.projects {
    gap: 24px;
}

@media screen and (max-width: 698px) {
    .home {
        max-width: 534px;
    }
}
@media screen and (max-width: 486px) {
    .home>* {
        padding: 0px 21px;
    }
}
@media screen and (max-width: 418px) {
    .home>* {
        padding: 0px 13px;
    }
}