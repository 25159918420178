.hero-container {
    gap: 55px;
    padding-bottom: 89px !important;
}

.hero-row-1 {
    display: flex;
    padding: 34px 21px 34px 34px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: 44.5px;
    background: #FFF;
    box-shadow: 0px 13px 55px -21px rgba(0, 0, 0, 0.15);
}

.hero-row-1>div {
    display: flex;
    gap: 21px;
    align-items: center;
}

.avatar {
    width: 76px;
    height: 76px;
    border-radius: 76px;
    flex-shrink: 0;
}

.hero-row-2 {
    display: flex;
    padding: 0px 34px;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    align-self: stretch;
    flex-wrap: wrap;
}

.hero-col-1 {
    min-width: 277px;
    flex: 1 0 0;
}

.hero-col-2 {
    display: flex;
    min-width: 144px;
    max-width: 233px;
    padding: 34px 0px 13px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 13px;
    flex: 1 0 0;
}

.hero-col-2 * {
    align-self: stretch;
}

@media screen and (max-width: 698px) {
    .hero-container {
        padding-bottom: 0px !important;
    }
    #pairitz {
        display: none;
    }
}

@media screen and (max-width: 486px) {
    .hero-row-1 {
        padding: 29px 13px 29px 29px;
        border-radius: 100px;
    }
    .hero-row-1>div {
        gap: 13px;
    }
    .avatar {
        width: 55px;
        height: 55px;
        flex-shrink: 0;
    }

    .hero-row-2 {
        display: flex;
        padding: 0px 34px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        align-self: stretch;
    }

    .hero-col-1 {
        align-self: stretch;
    }

    .hero-col-2 {
        align-self: stretch;
        max-width: unset;
    }
}
@media screen and (max-width: 418px) {
    #hi {
        display: none;
    }
}
@media screen and (max-width: 387px) {
    .hero-row-2 {
        padding: 0px 21px;
    }
}
@media screen and (max-width: 341px) {
    .hero-container h2 {
        font-size: 34px;
        line-height: 42px;
    }
}