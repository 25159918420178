.about {
    display: flex;
    flex-direction: row;
    padding: 0px 55px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 89px;
    align-self: stretch;
    flex-wrap: wrap;
    position: sticky;
    top: 0;
}

.about>.col-1 {
    display: flex;
    height: 843px;
    max-width: 466px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    border-radius: 17px;
    /* background: url(../Header/ryanpairitz-headshot.jpg) lightgray 50% / cover no-repeat; */
    position: sticky;
    top: 0;
    overflow: hidden;
    object-fit: cover;
}

.about>.col-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
    flex: 1 0 0;
}

.about>.col-2>div {
    display: flex;
    max-width: 521px;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 13px; */
    align-self: stretch;
}