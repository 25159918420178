.header-nav-container {
    box-sizing: border-box;
    display: flex;
    max-width: 1220px;
    width: 100%;
    padding: 66px 34px 21px 34px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
}

.logo-container {
    cursor: pointer;
    width: 44.499px;
    height: 40.173px;
    flex-shrink: 0;
}

nav {
    display: flex;
    align-items: center;
    gap: 55px;
}

.nav-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.nav-item svg {
    position: absolute;
    bottom: -5px;
    width: 5px;
    height: 5px;
    fill: var(--color-brand-green-normal, #28B861);
}

@media screen and (max-width: 387px) {
    .header-nav-container {
        padding: 66px 21px 21px 21px;
    }
}