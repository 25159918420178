footer {
    padding: 0px 21px 21px 21px;
}

.footer-content {
    background-color: white;
    display: flex;
    padding: 30px 34px;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
    flex: 1 0 0;
    border-radius: 27.5px;
}

.footer-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    row-gap: 55px;
    align-self: stretch;
    flex-wrap: wrap;
}

.footer-col-1 {
    display: flex;
    height: 40.173px;
    padding-right: 89px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-col-1>* {
    width: 44.499px;
    height: 40.173px;
    flex-shrink: 0;
}

.footer-col-2 {
    display: flex;
    min-width: 290px;
    max-width: 843px;
    flex-direction: column;
    align-items: flex-start;
    gap: 55px;
    flex: 1 0 0;
}

.footer-button {
    display: flex;
    padding: 13px 21px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 17px;
    background: var(--neutral-tan-300, #F6F5F2);
    color: var(--text-secondary, #525252);
}

.footer-button>h2 {
    align-self: stretch;
}

.footer-button>div {
    display: flex;
    column-gap: 21px;
    align-items: center;
    align-self: stretch;
    /* align-content: center; */
    justify-content: space-between;
    /* width: 100%; */
}

.footer-button-icon {
    display: flex;
    flex-shrink: 0;
    height: 55px;
    width: 55px;
    justify-content: center;
    align-items: center;
    border-radius: 27.5px;
    background: var(--color-brand-green-dark, #2C5C4F);
}

.footer-button-icon>svg {
    width: 21px;
    height: 21px;
    flex-shrink: 0;
}

.footer-nav {
    display: flex;
    padding-left: 21px;
    align-items: flex-start;
    gap: 34px;
}

.footer-nav>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.meta {
    color: var(--text-secondary, #525252);
}

@media screen and (max-width: 698px) {
    .footer-col-1 {
        padding-right: 55px;
    }
}

@media screen and (max-width: 486px) {
    .footer-col-1 {
        order: 1;
    }
}

@media screen and (max-width: 418px) {
    footer {
        padding: 0px;
    }

    .footer-content {
        padding: 34px 21px;
        border-radius: 0px;
    }
}

@media screen and (max-width: 243px) {
    .footer-nav {
        gap: 21px;
    }
}