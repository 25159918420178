.App {
  display: flex;
  max-width: 1220px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

h1,
h2 {
  color: var(--text-primary, #282828);

  font-family: "greycliff-cf", sans-serif;
  font-style: normal;
  font-weight: 900;
}

h1 {
  font-size: 60px;
  line-height: 76px;
  /* 126.667% */
  letter-spacing: -0.792px;
}

h2 {
  font-size: 43px;
  line-height: 55px;
  /* 127.907% */
}

h3 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 1 628;
}

h4 {
  color: var(--text-secondary, #525252);

  /* Small */
  font-family: "Mozaic HUM Variable", "mozaic-hum-variable", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: inherit;
  line-height: 16px;
  /* 145.455% */
  letter-spacing: 0.381px;
}

.small {
  color: var(--text-secondary, #525252);

  /* Small */
  font-family: "Mozaic HUM Variable", "mozaic-hum-variable", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 388;
  line-height: 16px;
  /* 145.455% */
  letter-spacing: 0.381px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 144px 0px;
  max-width: 1220px;
}

.link {
  text-decoration: underline;
}

.nav-link {
  text-decoration: none;
}